import { render, staticRenderFns } from "./BuildingUsageDetail.vue?vue&type=template&id=4a3f4817&scoped=true&"
import script from "./BuildingUsageDetail.vue?vue&type=script&lang=js&"
export * from "./BuildingUsageDetail.vue?vue&type=script&lang=js&"
import style0 from "./BuildingUsageDetail.vue?vue&type=style&index=0&id=4a3f4817&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a3f4817",
  null
  
)

export default component.exports