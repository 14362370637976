<template>
  <div class="container" style="height: 100%">
    <div class="row flex-grow-0">
      <div class="col col-md-5">
        <label class="col" for="buildusageCode">
          <span>{{ $t("건물 용도 코드") }}</span>
        </label>
        <div class="col">
          <input
            v-model="detailInfo.buildusageCode"
            type="text"
            disabled
            class="form-control"
            id="buildusageCode"
          />
        </div>
      </div>
      <div class="col col-md-5">
        <label class="col" for="buildusageCodeName">
          <span>{{ $t("건물 용도 코드명") }}</span>
        </label>
        <div class="col">
          <input
            v-model="detailInfo.buildusageCodeName"
            type="text"
            disabled
            class="form-control"
            id="buildusageCodeName"
          />
        </div>
      </div>
      <div class="col col-md-2">
        <label class="col" for="enabled">
          <span>{{ $t("사용여부") }}</span>
        </label>
        <div class="col">
          <select
            v-model="detailInfo.enabled"
            disabled
            id="enabled"
            class="form-control"
          >
            <option value="">선택</option>
            <option
              v-for="code in $store.state.commonCodes.enabled"
              :key="code.value"
              :value="code.value"
            >
              {{ code.text }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["detailInfo"],
  data() {
    return {};
  },
  async created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.row {
  margin-bottom: 16px;
}

.col {
  position: relative;
  width: 100%;
  padding-right: 4px;
  padding-left: 4px;
}
</style>