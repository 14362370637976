<template>
  <div class="ld-container" style="height: 90vh; width: 100%">
    <div class="row" style="height: 100%">
      <div class="list-area col-md-6">
        <div class="row">
          <header-box
            :title="`${$t('시스템 관리')} > ${$t('건물 용도 코드')}`"
          />
        </div>
        <div style="height: calc(100% - 40px)">
          <table-list
            ref="table-list"
            :title="'건물 용도 코드'"
            :columns="columns"
            :rows="buildingList"
            :mode="'view'"
            :keyField="'buildusageCode'"
            :transCodes="transCodes"
            :useMaxHeight="true"
            @row-click="handleItemClick"
          />
        </div>
      </div>
      <div class="detail-area col-md-6">
        <BuildingUsageDetail
          ref="BuildingUsageDetail"
          :title="'건물 용도 코드 상세'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@views/component/headerBox/analysis/Header.vue";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import backEndApi from "@src/api/backEndApi";
import BuildingUsageDetail from "./BuildingUsageDetail.vue";

export default {
  props: [],
  components: {
    HeaderBox,
    TableList,
    BuildingUsageDetail,
  },
  data() {
    return {
      buildingList: [],
      columns: [
        {
          label: this.$t("건물 용도 코드"),
          field: "rowTitle",
          type: "text",
          compositionFields: ["buildusageCodeName", "buildusageCode"],
        },
        {
          label: this.$t("사용여부"),
          field: "enabled",
          width: "100px",
          type: "text",
          sortable: false,
        },
      ],
      transCodes: [
        {
          field: "enabled",
          codes: this.$store.state.commonCodes.enabled,
        },
      ],
    };
  },
  watch: {},
  computed: {},
  async created() {
    await this.getBuildUsageCodeList();
  },
  mounted() {},
  methods: {
    async getBuildUsageCodeList() {
      let success = false;
      try {
        let result = await backEndApi.codes.getBuildUsageCodeList();
        if (result.status == 200) {
          this.buildingList = result.data;
          success = true;
        } else this.alertWarning(`${result.data.message}`);
      } catch (e) {
        console.error(e);
      }
      return success;
    },

    async handleItemClick(row, idx, toggle, check) {
      if (toggle || check) return;
      await this.$refs["BuildingUsageDetail"].loadData(row.buildusageCode);
    },
  },
};
</script>

<style scoped>
.ld-container {
  height: 90vh;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px 30px 10px 20px;
}

.ld-container .list-area {
  height: 100%;
  overflow: hidden;
  padding: 10px;
}

.ld-container .detail-area {
  height: 100%;
  margin-right: 00px;
  padding: 0px 10px 0px 10px;
  border-top: 1px solid #f4f4f4;
  border-right: 2px solid #e7e7e7;
  border-bottom: 2px solid #e7e7e7;
  border-left: 1px solid #f4f4f4;
  background-color: #f9f9f9;
  border-radius: 10px;
}
</style>