<template>
  <div class="detail-container">
    <div class="detail-placeholder" v-if="!detailInfo">
      <span class="detail-notification-icon">
        <i class="fas fa-external-link-alt"></i>
      </span>
      <span class="detail-notification-text">건물 용도 코드를 선택하세요.</span>
    </div>

    <div style="height: calc(100% - 70px)" v-if="detailInfo">
      <!-- 2.1 상세 정보 헤더 영역 -->
      <div class="detail-header" v-if="detailInfo">
        <div class="detail-title">
          <span class="title">{{ viewTitle }}</span>
        </div>
      </div>

      <div class="detail-content" v-if="detailInfo">
        <BaseInfoPanel :detailInfo="detailInfo" />
      </div>
    </div>

    <div class="detail-bottom">
      <div class="detail-bottom-buttons">
        <button
          v-if="detailInfo"
          type="button"
          class="btn btn-secondary detail-btn"
          @click="handleClose($event)"
        >
          닫기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInfoPanel from "./panels/BaseInfoPanel.vue";
import backEndApi from "@src/api/backEndApi";

export default {
  props: ["title"],
  components: {
    BaseInfoPanel,
  },
  data() {
    return {
      detailInfo: null,
    };
  },
  watch: {},
  computed: {
    viewTitle() {
      return this.title;
    },
  },
  created() {},
  mounted() {},
  methods: {
    async loadData(buildusageCode) {
      try {
        let result = await backEndApi.codes.getBuildUsageCode(buildusageCode);
        if (result.status == 200) {
          this.detailInfo = result.data;
        } else {
          this.alertWarning(result.data.message);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async handleClose() {
      this.detailInfo = null;
    },
  },
};
</script>

<style scoped>
.detail-placeholder {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-notification-icon {
  padding: 1rem;
  color: #444;
  font-size: 48px;
}

.detail-notification-text {
  font-size: 15px;
  font-weight: bold;
}

.detail-container {
  height: 100%;
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 10px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  font-size: 12px;
}

.detail-header {
  margin-bottom: 0px !important;
  padding: 8px 16px 8px 16px;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px 6px 0px 0px;
  background-color: #f1f1f1;
  border: solid #ececec 1px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
}

.detail-header .detail-title {
  padding: 0 !important;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.title {
  font-weight: 600;
  margin-right: 20px;
}

.detail-content {
  height: Calc(100% - 50px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 2px;
  padding: 20px;
  overflow: hidden;
  background-color: white;
  border: solid #ececec 1px;
  border-radius: 0px 0px 6px 6px;
}

.detail-control {
  margin-bottom: 8px !important;
  display: flex;
  justify-content: flex-end;
}

.detail-bottom {
  margin-top: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40 px;
}

.detail-bottom-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 4px;
}

.detail-btn {
  /* height: 50px; */
  flex: 1;
  margin: 0px 4px 0px 4px;
}
</style>